import React from "react";  
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Heading from "@ui/heading";
import Button from "@ui/button";
import { SectionWrap, InnovationImgWrap, InnovationListWrap, InnovationListItem, AndovarAcademyWrap} from "./style";
const TechnologyInnovation = ({data}) => {  
    return(
        <SectionWrap id={data.section}>
            <Container>
                <SectionTitle
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                />
                {data.images && data.images.length > 0 && (  
                    <InnovationImgWrap>  
                        <Image  
                            src={data.images[0]?.src}  
                            alt={data.images[0]?.alt || "Image"}  
                            title={data.images[0]?.alt || "Image"}  
                        />  
                    </InnovationImgWrap>  
                )}
                <InnovationListWrap>
                    {data &&
                    data.items.map((el, index) => (
                        <InnovationListItem key={index} href={el.path} title={el.title} target="_blank"> 
                            <Image
                                src={el.images[0]?.src}
                                alt={el.images[0]?.alt || "Icon"}
                                title={el.images[0]?.alt || "Icon"}
                            />
                            <Heading as="h4" className="innovationHeading">{el.title}</Heading>
                        </InnovationListItem>
                    ))}
                </InnovationListWrap>
            </Container>
            <AndovarAcademyWrap>
                    {data?.headings?.[0] && (
                        <Heading as={data.headings[0]?.level}>
                            {data.headings[0]?.content}
                        </Heading>
                    )}
                    {data?.buttons?.map(({ id,index, path, content, ...rest }) => (
                        <Button key={id} path={path} title={content} {...rest} className="academyBtn">
                            {content}
                        </Button>
                    ))}
            </AndovarAcademyWrap>
        </SectionWrap>
    );
}; 
TechnologyInnovation.propTypes = {
    items: PropTypes.object,
};
export default TechnologyInnovation;