import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    padding:30px 0 0;
    ${device.small} {
        padding:60px 0 0;
    }
    ${device.large} {
        padding:80px 0 0;
    }
    ${device.xlarge} {
        padding:100px 0 0;
    }
`;
export const InnovationImgWrap = styled.div`
    text-align:center;
`
export const InnovationListWrap = styled.div`
    padding-top:40px;
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-rows:repeat(3, 1fr);
    grid-gap:10px;
    align-items:center;
    ${device.small} {
        grid-template-columns:repeat(3, 1fr);
        grid-template-rows:repeat(3, 1fr);
        padding-top:60px;
    }
    ${device.medium} {
        grid-template-columns:repeat(4, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.large} {
        padding-top:80px;
        grid-template-columns:repeat(5, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.xlarge} {
        padding-top:100px;
        grid-gap:20px;
    }
`
export const InnovationListItem = styled.a`
    background-image: linear-gradient(0deg, #15054f 0%, #561783 99%, #561783 100%);
    min-height: 175px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding: 25px 5px 20px;
    transition:all .7s ease-in-out;
    width:100%;
    &:hover{
        transform: scale(1.05);
        animation:bgColor .5s 1 linear;
        background-image: linear-gradient(rgb(249, 79, 25), rgb(249, 79, 25));
    }
    ${device.medium}{
        min-height: 200px;
    }
    ${device.large} {
        &:nth-child(2), &:nth-child(5), &:nth-child(8){
            grid-row:2;
        }
        &:nth-child(3){
            grid-row:1 / span 2;
            grid-column: 2;
        }
        &:nth-child(6){
            grid-row:1 / span 2;
            grid-column: 4;
        }
        
    }
    ${device.xlarge}{
        min-height: 232px;
        padding: 35px 15px 25px;
    }
    .innovationHeading{
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1.3;
        color: #ffffff;
        margin:0;
        font-weight:400;
        transition:all .5s ease-in-out;
        ${device.large}{
            font-size:18px;
        }
        ${device.xlarge}{
            font-size:20px;
        }
    }
    img{
        max-width:65px !important;
        ${device.medium}{
            max-width:75px !important;
        }
        ${device.xlarge}{
            max-width:100% !important;
        }
    }
`
export const AndovarAcademyWrap = styled.div`
    background-color: #fa4f19;
    padding:30px 0;
    margin-top:40px;
    text-align:center;
    ${device.medium}{
        padding:50px 0;
        margin-top:60px;
    }
    ${device.xlarge}{
        padding:80px 0;
        margin-top:100px;
    }
    h4 {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 700;
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
    .academyBtn {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        border-radius: 0;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 400;
        transition:all .5s ease-in-out;
        height:auto;
        &:hover{
            text-decoration:underline;
            color:#fff;
        }
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
`